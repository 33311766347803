import React from 'react';
import { Routes, Route, Link, useNavigate, useParams } from "react-router-dom";
import DOM from 'react-dom'

import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import Flickity from 'react-flickity-component';

import axios from 'axios';
import Helmet from 'react-helmet';

import plusIcon from '../assets/img/plus.svg';
import closeIcon from '../assets/img/close.svg';

import logo_l1 from '../assets/img/logo/l1.svg';
import logo_l2 from '../assets/img/logo/l2.svg';
import logo_l3 from '../assets/img/logo/l3.svg';
import logo_r1 from '../assets/img/logo/r1.svg';
import logo_r2 from '../assets/img/logo/r2.svg';
import logo_r3 from '../assets/img/logo/r3.svg';
import logo_v from '../assets/img/logo/v.svg';

class Project extends React.Component {
  constructor(props) {
  	super(props);

  	this.project_slug = this.props.params.project_slug;

  	this.countdownInterval = null;
  	this.time = 10;
  	this.lastFetched = '';

  	this.state = {
	    slider: [],
	    countdownActive: false,
	    countdownTime: this.time,
	    currentSlide: 1,
	    sliderOpen: false,
	    flickityOptions: {
				initialIndex: 1,
				contain: true,
				fade: true,
				pageDots: false,
				arrowShape: "M47.5269119,11.2720779 L53.1837662,16.9289322 C53.5742905,17.3194565 53.5742905,17.9526215 53.1837662,18.3431458 L27.071,44.455039 L109,44.4558441 C109.552285,44.4558441 110,44.9035594 110,45.4558441 L110,53.4558441 C110,54.0081289 109.552285,54.4558441 109,54.4558441 L27.071,54.455039 L53.1837662,80.5685425 C53.5742905,80.9590668 53.5742905,81.5922318 53.1837662,81.9827561 L47.5269119,87.6396103 C47.1363876,88.0301346 46.5032227,88.0301346 46.1126984,87.6396103 L14.3053057,55.8320077 C14.3011417,55.8279834 14.297004,55.8239159 14.2928932,55.8198052 L12.928,54.455039 L11,54.4558441 C10.4477153,54.4558441 10,54.0081289 10,53.4558441 L10,51.525039 L8.63603897,50.1629509 C8.24551468,49.7724266 8.24551468,49.1392616 8.63603897,48.7487373 L10,47.385039 L10,45.4558441 C10,44.9035594 10.4477153,44.4558441 11,44.4558441 L12.928,44.455039 L14.2928932,43.0918831 L14.308,43.077039 L46.1126984,11.2720779 C46.5032227,10.8815536 47.1363876,10.8815536 47.5269119,11.2720779 Z"
			},
	    content: this.props.preLoadedContent.content,
	    secondLineActive: false
	  };
	 }

  componentDidMount() {
  	document.querySelector('body').classList.add('light-bg');
  	document.querySelector('body').classList.remove('dark-bg');

  	setTimeout(() => {
  		this.setState({
  			secondLineActive: true
  		})
  	}, 200);

  	this.updateProject.bind(this)();
  }

  componentWillUnmount() {
  	if(window.innerWidth > 767) {
  		const projectContainer = DOM.findDOMNode(this.projectContainer);
	  	const scrollOffset = document.documentElement.scrollTop || document.body.scrollTop;

	  	localStorage.setItem('projectOutro', projectContainer.outerHTML);
	  	localStorage.setItem('projectScrollOffset', scrollOffset);
  	} else {
  		const scrollableDiv = document.createElement('div');

  		localStorage.setItem('projectOutro', scrollableDiv);
	  	localStorage.setItem('projectScrollOffset', 0);
  	}

  	if(window.innerWidth > 992) {
	  	if(this.countdownInterval !== null) {
	  		clearInterval(this.countdownInterval);
	  	}

	  	window.removeEventListener('scroll', this.onWindowScroll.bind(this));
	  }
  }

  onWindowScroll() {
  	if(window.innerWidth > 992) {
	  	this.isVisible = false;
	  	this.countdown = null;

	  	const element = document.querySelector('.project-detail__next');

	  	if(element !== null) {
		  	const elementDimensions = element.getBoundingClientRect();

		  	const relativeTop = elementDimensions.top;
		  	const relativeBottom = elementDimensions.top + elementDimensions.height;


		  	if(relativeBottom < window.innerHeight) {
		  		this.isVisible = true;
		  	} else {
		  		this.isVisible = false;
		  	}

		  	if(this.state.countdownActive !== this.isVisible) {
			  	this.setState({
			  		countdownActive: this.isVisible
			  	});
			  }
			}
		}
  }

  componentDidUpdate() {
  	if(window.innerWidth > 992) {
	  	if(this.state.countdownActive === true) {
	  		clearInterval(this.countdownInterval);
	  		this.countdownInterval = setInterval(() => {
	  			this.time--;

	  			if(this.time === 0) {
	  				this.updateProject(this.loadContent(this.state.content, 'next_project/post_name'));
	  				clearInterval(this.countdownInterval);

	  				this.props.navigate(`/project/${this.loadContent(this.state.content, 'next_project/post_name')}`);
	  			}

	  			if(document.querySelector('#time') !== null) {
			  		document.querySelector('#time').innerHTML = this.time;
			  	}
	  		}, 1000);
	  	} else {
	  		if(this.countdownInterval !== null) {
	  			clearInterval(this.countdownInterval);
	  			this.time = 10;
	  		}
	  	}

	  	if(document.querySelector('#time') !== null) {
	  		document.querySelector('#time').innerHTML = this.time;
	  	}
	  }
  }

  buildSlider(content) {
  	let rules = [];

  	if(content !== undefined && content !== null) {
  		Object.keys(content).forEach((key) => {
  			const value = content[key];

  			if(value instanceof Array) {
  				value.forEach((nestedItem) => {
  					Object.keys(nestedItem).forEach((nestedKey) => {
  						if(nestedItem[nestedKey].sizes !== null && nestedItem[nestedKey].sizes !== undefined) {
		  					rules.push(nestedItem[nestedKey].sizes['2048x2048']);
		  				}
  					});
  				});
  			} else {
  				if(value.sizes !== null && value.sizes !== undefined) {
  					rules.push(value.sizes['2048x2048']);
  				}
  			}
  		})
  	}

  	rules = rules.filter((rule) => {
  		if(rule.endsWith('.png') || rule.endsWith('.jpg') || rule.endsWith('.jpeg') || rule.endsWith('.svg') || rule.endsWith('.webp')) {
  			return true;
  		}

  		if(rule.indexOf('https://images.unsplash.com/') > -1) {
  			return true;
  		}

  		return false;
  	});

  	this.setState({
  		slider: rules
  	});
  }

  openSlider(url, e) {
  	e.preventDefault();

  	if(window.innerWidth < 767) {
  		return	false;
  	}

  	this.setState({
  		sliderOpen: true
  	}, () => {
  		const index = this.state.slider.indexOf(url);

  		if(index > -1) {
  			this.flkty.select(index, false, true);
  		}
  	});
  }

  closeSlider(e) {
  	e.preventDefault();

  	this.setState({
  		sliderOpen: false
  	});
  }

  setResponsiveValue(desktop, mobile) {
  	if(window.innerWidth > 992) {
  		return desktop;
  	} else {
  		return mobile;
  	}
  }

  updateProject(slug) {
  	if(slug) {
  		window.scrollTo(0, 0);
  	}

  	axios.get(`${this.props.baseUrl}/project?project_slug=${(slug !== null && slug !== undefined ? slug : this.project_slug)}`)
			.then(res => {
				this.lastFetched = this.project_slug;
				this.time = 10;

				if(!slug) {
		  		window.scrollTo(0, 0);
		  	}

				this.setState({
	  			content: res.data.content
	  		})

	  		this.buildSlider.bind(this)(this.state.content);

				this.flkty.on('change', () => {
					this.currentSlide = this.flkty.selectedIndex + 1;

					document.querySelector('.project-detail__carousel-count span').innerHTML = this.currentSlide;
				});

				if(window.innerWidth > 992) {
					window.addEventListener('scroll', this.onWindowScroll.bind(this));
				}
			});
  }

  renderBlocks() {
  	if(this.state.content !== undefined && this.state.content !== null && this.state.content.blocks !== undefined && this.state.content.blocks !== null) {
  		return this.state.content.blocks.map((item, count) => {
  			return (
  				<div key={count}>
  					{this.loadItem.bind(this)(item)}
  				</div>
  			)
  		})
  	}
  }

	copyrightYear() {
    const today = new Date();

    return today.getFullYear();
  }

  renderSlides() {
  	if(this.state.slider !== undefined && this.state.slider !== null) {
  		return this.state.slider.map((item, count) => {
  			return (
  				<div className="project-detail__slide" key={count}>
  					<img src={item} />
  				</div>
  			);
  		});
  	}
  }

  loadItem(item) {
  	if(item.acf_fc_layout === 'full-boxed') {
  		return (
  			<div className="container-fluid">
					<div className="row ">
						<div className="col-md-12">
							<div className="project-detail__full-boxed">
			  				<Parallax speed={this.setResponsiveValue(14, 3)} scale={[this.setResponsiveValue(0.8, 0.75), 1, [0, 1, 0.5, 1]]}>
			  					<figure onClick={this.openSlider.bind(this, item['full-boxed_afbeelding']['sizes']['2048x2048'])}>
				  					<img src={item['full-boxed_afbeelding']['sizes']['2048x2048']} className="slider-open-photo" />
				  					<div className="clickable-plus clickable-plus--right-bottom"><img src={plusIcon}/></div>
				  				</figure>
			  				</Parallax>
			  			</div>
			  		</div>
			  	</div>
  			</div>
  		);
  	}

  	if(item.acf_fc_layout === 'quote') {
  		if(item.volgorde_quote_en_afbeelding === 'quote_left') { 
	  		return (
	  			<div className="project-detail__quote container-fluid">
	  				<div className="row align-items-center">
	  					<div className="col-md-5 offset-md-1">
			  				<Parallax speed={this.setResponsiveValue(5, 1)}>
			  					<blockquote className="project-detail__quote-text">
			  						{item.quote_quote}
			  						<span className="project-detail__quote-author">{item.quote_auteur}</span>
			  					</blockquote>
			  				</Parallax>
	  					</div>
	  					<div className="col-md-6">
	  						<Parallax speed={this.setResponsiveValue(14, 3)} scale={[this.setResponsiveValue(0.8, 0.75), 1, [0, 1, 0.5, 1]]}>
			  					<figure onClick={this.openSlider.bind(this, item['quote_afbeelding']['sizes']['2048x2048'])}>
				  					<img src={item['quote_afbeelding']['sizes']['2048x2048']} className="project-detail__quote-image slider-open-photo" />
				  					<div className="clickable-plus clickable-plus--right-bottom"><img src={plusIcon}/></div>
				  				</figure>
			  				</Parallax>
	  					</div>
	  				</div>
	  			</div>
	  		);
	  	} else {
	  		return (
	  			<div className="project-detail__quote container-fluid">
	  				<div className="row align-items-center">
	  					<div className="col-md-5">
	  						<Parallax speed={this.setResponsiveValue(14, 3)} scale={[this.setResponsiveValue(0.8, 0.75), 1, [0, 1, 0.5, 1]]}>
			  					<figure onClick={this.openSlider.bind(this, item['quote_afbeelding']['sizes']['2048x2048'])}>
				  					<img src={item['quote_afbeelding']['sizes']['2048x2048']} className="project-detail__quote-image slider-open-photo" />
				  					<div className="clickable-plus clickable-plus--right-bottom"><img src={plusIcon}/></div>
				  				</figure>
			  				</Parallax>
	  					</div><div className="col-md-5 offset-md-2">
			  				<Parallax speed={this.setResponsiveValue(5, 1)}>
			  					<blockquote className="project-detail__quote-text">
			  						{item.quote_quote}
			  						<span className="project-detail__quote-author">{item.quote_auteur}</span>
			  					</blockquote>
			  				</Parallax>
	  					</div>
	  					
	  				</div>
	  			</div>
	  		);
	  	}
  	}


  	if(item.acf_fc_layout === 'large-small') {
  		return (
  			<div className="project-detail__semi container-fluid">
  				<div className="row align-items-center">
  					<div className="col-md-6">
		  				<Parallax speed={this.setResponsiveValue(14, 3)} scale={[this.setResponsiveValue(0.8, 0.75), 1, [0, 1, 0.5, 1]]}>
		  					<figure onClick={this.openSlider.bind(this, item['large-small_afbeelding_links']['sizes']['2048x2048'])}>
			  					<img src={item['large-small_afbeelding_links']['sizes']['2048x2048']} className="project-detail__semi-image project-detail__semi-image--large slider-open-photo"  />
			  					<div className="clickable-plus clickable-plus--right-bottom"><img src={plusIcon}/></div>
			  				</figure>
		  				</Parallax>
  					</div>
  					<div className="col-md-5 offset-md-1">
  						<Parallax speed={this.setResponsiveValue(7, 3)} scale={[this.setResponsiveValue(1, 1), 1, [0, 1, 0.5, 1]]}>
		  					<figure onClick={this.openSlider.bind(this, item['large-small_afbeelding_rechts']['sizes']['2048x2048'])}>
			  					<img src={item['large-small_afbeelding_rechts']['sizes']['2048x2048']} className="project-detail__semi-image project-detail__semi-image--small slider-open-photo" />
			  					<div className="clickable-plus clickable-plus--right-bottom"><img src={plusIcon}/></div>
			  				</figure>
		  				</Parallax>
  					</div>
  				</div>

  			</div>
  		);
  	}

  	if(item.acf_fc_layout === 'small-large') {
  		return (
  			<div className="project-detail__semi container-fluid">
  				<div className="row align-items-center">
  					<div className="col-md-5">
  						<Parallax speed={this.setResponsiveValue(7, 3)} scale={[this.setResponsiveValue(1, 1), 1, [0, 1, 0.5, 1]]}>
		  					<figure onClick={this.openSlider.bind(this, item['large-small_afbeelding_rechts']['sizes']['2048x2048'])}>
			  					<img src={item['large-small_afbeelding_rechts']['sizes']['2048x2048']} className="project-detail__semi-image project-detail__semi-image--small slider-open-photo" />
			  					<div className="clickable-plus clickable-plus--right-bottom"><img src={plusIcon}/></div>
			  				</figure>
		  				</Parallax>
  					</div>

  					<div className="col-md-6 offset-md-1">
		  				<Parallax speed={this.setResponsiveValue(14, 3)} scale={[this.setResponsiveValue(0.8, 0.75), 1, [0, 1, 0.5, 1]]}>
		  					<figure onClick={this.openSlider.bind(this, item['large-small_afbeelding_links']['sizes']['2048x2048'])}>
			  					<img src={item['large-small_afbeelding_links']['sizes']['2048x2048']} className="project-detail__semi-image project-detail__semi-image--large slider-open-photo" />
			  					<div className="clickable-plus clickable-plus--right-bottom"><img src={plusIcon}/></div>
			  				</figure>
		  				</Parallax>
  					</div>
  				</div>

  			</div>
  		);
  	}

  	if(item.acf_fc_layout === 'full') {
  		return (
  			<div className="project-detail__full">
  				<Parallax speed={this.setResponsiveValue(14, 3)}>
  					<figure>
	  					<img src={item['full_afbeedling']['sizes']['2048x2048']} onClick={this.openSlider.bind(this, item['full_afbeedling']['sizes']['2048x2048'])} className="slider-open-photo" />
	  					<div className="clickable-plus clickable-plus--right-bottom"><img src={plusIcon}/></div>
	  				</figure>
  				</Parallax>
  			</div>
  		);
  	}
  }

  loadContent(object, path) {
  	if(object === '' || object === null || object === undefined || Object.keys(object).length === 0) {
      return '	';
    } else {
      const splittedPath = path.split('/');

      let nestedValue = JSON.parse(JSON.stringify(object));

      splittedPath.forEach((pathPart) => {
        nestedValue = nestedValue[pathPart];

      });

      nestedValue = nestedValue.split('\r\n').join('<br/>');

      return nestedValue;
    }
  }

  render() {
    return (
    	<div>
    		<Helmet>
            <meta charSet="utf-8" />
            <title>Project - Marco van Veldhuizen</title>
            <meta name="description" content="Marco van Veldhuizen is een jong ontwerpbureau met als uitgangspunt voor alle opdrachten het streven naar een totaalconcept, waardoor exterieur, interieur en tuinplan steeds in harmonie met elkaar zijn." />
        </Helmet>
				<ParallaxProvider>
					<div
						className="project-detail"
						ref={ref => {
			        this.projectContainer = ref
			      }}
					>
						<div className="project-detail__header">
							<div className="container-fluid">
								<div className="row align-items-center">
									<div className="col-md-4 offset-md-1">
										<Parallax speed={this.setResponsiveValue(10, 0)} scale={[this.setResponsiveValue(1, 1), 1, [0, 1, 0.5, 1]]}>
											<div className="project-detail__photo slider-open-photo" style={{backgroundImage: `url(${this.loadContent(this.state.content, 'image/sizes/2048x2048')})`}} onClick={this.openSlider.bind(this, this.loadContent(this.state.content, 'image/sizes/2048x2048'))}>
												<div className="clickable-plus clickable-plus--right-bottom"><img src={plusIcon}/></div>
											</div>
										</Parallax>
									</div>
									<div className="col-md-5 offset-md-2">
										<Parallax speed={this.setResponsiveValue(-10, 0)}>
											<h1 className="project-detail__title">{this.loadContent(this.state.content, 'title')}</h1>
											<h4 className="project-detail__tagline">{this.loadContent(this.state.content, 'tagline')}</h4>
											<div className="project-detail__dummy-cta button">x</div>
										</Parallax>
									</div>
								</div>
							</div>
						</div>

						<div className={`project-detail__second ${(this.state.secondLineActive ? 'project-detail__second--active' : 'project-detail__second--inactive')}`}>
							<div className="container-fluid">
								<div className="row align-items-end">
									<div className="col-md-4">
										<Parallax speed={this.setResponsiveValue(14, 3)} scale={[this.setResponsiveValue(0.8, 0.75), 1, [0, 1, 0.5, 1]]}>
											<div className="project-detail__second-photo slider-open-photo project-detail__second-photo--small" style={{backgroundImage: `url(${this.loadContent(this.state.content, 'image_2/sizes/2048x2048')})`}} onClick={this.openSlider.bind(this, this.loadContent(this.state.content, 'image_2/sizes/2048x2048'))}>
												<div className="clickable-plus clickable-plus--right-bottom"><img src={plusIcon}/></div>
											</div>
										</Parallax>
									</div>

									<div className="col-md-6 offset-md-2">
										<Parallax speed={this.setResponsiveValue(0, 3)} scale={[this.setResponsiveValue(0.95, 0.75), 1, [0, 1, 0.5, 1]]}>
											<div className="project-detail__second-photo slider-open-photo" style={{backgroundImage: `url(${this.loadContent(this.state.content, 'image_3/sizes/2048x2048')})`}} onClick={this.openSlider.bind(this, this.loadContent(this.state.content, 'image_3/sizes/2048x2048'))}>
												<div className="clickable-plus clickable-plus--right-bottom"><img src={plusIcon}/></div>
											</div>
										</Parallax>
									</div>
								</div>
							</div>
						</div>

						{this.renderBlocks.bind(this)()}

						<footer className="project-detail__footer">

							<div className="project-detail__next">
								<p className="project-detail__next-title">Next project</p>
								<Link to={`/project/${this.loadContent(this.state.content, 'next_project/post_name')}`} onClick={this.updateProject.bind(this, this.loadContent(this.state.content, 'next_project/post_name'))} className="project-detail__next-button">
									<span>{this.loadContent(this.state.content, 'next_project/post_title')}</span>

									<div className="project-detail__next-countdown">
										<div id="time" className="project-detail__next-countdown-number">10</div>
										<div className="project-detail__next-countdown-title">View project</div>
									</div>
								</Link>

								
							</div>

							<p className="project-detail__copyright">&copy; {this.copyrightYear()} Studio Marco van Veldhuizen</p>

							<div className="project-detail__footer-logo">
								<img src={logo_l3} />
								<img src={logo_l2} />
								<img src={logo_l1} />
								<img src={logo_v} />
								<img src={logo_r1} />
								<img src={logo_r2} />
								<img src={logo_r3} />
							</div>
						</footer>
					</div>
				</ParallaxProvider>

				<div className={`project-detail__carousel-container ${this.state.sliderOpen ? 'project-detail__carousel-container--active' : ''}`}>
					<Flickity
						className={'project-detail__carousel'}
						elementType={'div'}
						options={this.state.flickityOptions}
						flickityRef={c => this.flkty = c}
						disableImagesLoaded={false} 
						reloadOnUpdate
					>
						{this.renderSlides.bind(this)()}
					</Flickity>

					<div className="project-detail__carousel-count"><span>1</span> / {this.state.slider.length}</div>
					<div className="project-detail__carousel-close-container">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<button className="project-detail__carousel-close" onClick={this.closeSlider.bind(this)}><img src={closeIcon} /></button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
    );
  }
}

function withNavigation(Component) {
  return props => <Component {...props} params={useParams()} navigate={useNavigate()} />;
}

export default withNavigation(Project);
