import React from 'react';
import DOM from 'react-dom'

import { Routes, Route, Link } from "react-router-dom";
import axios from 'axios';
import Helmet from 'react-helmet';

import logo from '../assets/img/logo.svg';
import facebook from '../assets/img/facebook.svg';
import instagram from '../assets/img/instagram.svg';

export default class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: {}
    }

    this.state = {
      animationShow: false,
    }
  }

  componentWillUnmount() {
    const pageContainer = DOM.findDOMNode(this.pageContainer);

    localStorage.setItem('projectOutro', pageContainer.outerHTML);
    localStorage.setItem('projectScrollOffset', 0);
  }

  componentDidMount() {
    document.querySelector('body').classList.remove('light-bg');
    document.querySelector('body').classList.add('dark-bg');
    axios.get(`${this.props.baseUrl}/content`)
      .then(res => {
        const freshApiData = res.data.content;

        this.setState({
          content: freshApiData
        });
      });

    setTimeout(() => {
      this.setState({
        animationShow: true
      });
    }, 500);
  }

  copyrightYear() {
    const today = new Date();

    return today.getFullYear();
  }

  loadContent(object, path) {
    if(object === undefined || Object.keys(object).length === 0) {
      return ' ';
    } else {
      const splittedPath = path.split('/');

      let nestedValue = JSON.parse(JSON.stringify(object));

      splittedPath.forEach((pathPart) => {
        nestedValue = nestedValue[pathPart];
      });

      nestedValue = nestedValue.split('\r\n').join('<br/>');

      return nestedValue;
    }  
  }

  footerLinks(data) {
    if(data !== undefined && Object.keys(data).length > 0) {
      const links = data.footer.footer_links;

      return links.map((link, count) => {
        if(!link.footer_links_link.url || !link.footer_links_link.title) {
          return;
        }
        
        if(link.footer_links_link.url.indexOf('https://') > -1) {
          return (
            <li key={count} className="copyright__item"><a href={link.footer_links_link.url} target="_blank">{link.footer_links_link.title}</a></li>
          );
        } else {
          return (
            <li key={count} className="copyright__item"><Link to={link.footer_links_link.url}>{link.footer_links_link.title}</Link></li>
          );
        }
       
      });
    }
  }

  render() {
    return (
      <div
        ref={ref => {
          this.pageContainer = ref
        }}
        className={`page-detail ${(this.state.animationShow ? 'page-detail--show' : 'page-detail--hidden')}`}
      >
        <Helmet>
            <meta charSet="utf-8" />
            <title>Contact - Marco van Veldhuizen</title>
            <meta name="description" content="Marco van Veldhuizen is een jong ontwerpbureau met als uitgangspunt voor alle opdrachten het streven naar een totaalconcept, waardoor exterieur, interieur en tuinplan steeds in harmonie met elkaar zijn." />
        </Helmet>
        <main className="contact-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="contact-page__title">
                  <h1>{this.loadContent(this.state.content, 'contact/contact_titel')}</h1>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10 offset-md-2 col-lg-6 offset-lg-6">

                <div className="contact-table">
                  <div className="contact-table__row">
                    <div className="contact-table__title">{this.loadContent(this.state.content, 'contact/contact_telefoon_label')}</div>
                    <div className="contact-table__value"><a href={this.loadContent(this.state.content, 'contact/contact_telefoon_link')}>{this.loadContent(this.state.content, 'contact/contact_telefoon_waarde')}</a></div>
                  </div>

                   <div className="contact-table__row">
                    <div className="contact-table__title">{this.loadContent(this.state.content, 'contact/contact_email_label')}</div>
                    <div className="contact-table__value"><a href={this.loadContent(this.state.content, 'contact/contact_email_link')}>{this.loadContent(this.state.content, 'contact/contact_email_waarde')}</a></div>
                  </div>

                   <div className="contact-table__row">
                    <div className="contact-table__title">{this.loadContent(this.state.content, 'contact/contact_adres_label')}</div>
                    <div className="contact-table__value contact-table__value--has-button">
                      <div dangerouslySetInnerHTML={{__html: this.loadContent(this.state.content, 'contact/contact_adres_waarde')}}></div>
                      <a href={this.loadContent(this.state.content, 'contact/contact_adres_link')} target="_blank" className="button button--wide button--light-desktop">Route</a>
                    </div>
                  </div>
                </div>

                <div className="social-container">
                  <div className="social-container__media">
                    <ul>
                      <li>
                        <a href={this.loadContent(this.state.content, 'contact/contact_facebook_link')} target="_blank">
                          <img src={facebook} />
                          <span>Facebook</span>
                        </a>
                      </li>
                      <li>
                        <a href={this.loadContent(this.state.content, 'contact/contact_instagram_link')} target="_blank">
                          <img src={instagram} />
                          <span>Instagram</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="social-container__logo">
                    <img src={logo} />
                  </div>
                </div>

                <div className="copyright">
                  <ul className="copyright__list">
                    <li className="copyright__item">&copy; {this.copyrightYear()} Studio Marco van Veldhuizen</li>
                    {this.footerLinks(this.state.content)}
                  </ul>
                </div>

              </div>
            </div>
          </div>

        </main>
      </div>
    );
  }
}
