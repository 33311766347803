import React from 'react';
import DOM from 'react-dom'

import { Routes, Route, Link } from "react-router-dom";
import axios from 'axios';
import Helmet from 'react-helmet';

import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import Flickity from 'react-flickity-component'

import plusIcon from '../assets/img/plus.svg';
import closeIcon from '../assets/img/close.svg';

import slogan from '../assets/img/slogan_about.svg';
import sloganMobile from '../assets/img/slogan_about_small.svg';

import logo_l1 from '../assets/img/logo/l1.svg';
import logo_l2 from '../assets/img/logo/l2.svg';
import logo_l3 from '../assets/img/logo/l3.svg';
import logo_r1 from '../assets/img/logo/r1.svg';
import logo_r2 from '../assets/img/logo/r2.svg';
import logo_r3 from '../assets/img/logo/r3.svg';
import logo_v from '../assets/img/logo/v.svg';

export default class About extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      animationShow: false,
      content: {},
      flickityOptions: {
        initialIndex: 1,
        contain: true,
        fade: true,
        pageDots: false,
        prevNextButtons: false,
        autoPlay: 3000,
        adaptiveHeight: false
      },
    }
  }

  copyrightYear() {
    const today = new Date();

    return today.getFullYear();
  }

  componentWillUnmount() {
    const pageContainer = DOM.findDOMNode(this.pageContainer);

    localStorage.setItem('projectOutro', pageContainer.outerHTML);
    localStorage.setItem('projectScrollOffset', 0);
  }

  componentDidMount() {
    document.querySelector('body').classList.add('light-bg');
    document.querySelector('body').classList.remove('dark-bg');

    window.scrollTo(0, 0);

    setTimeout(() => {
      this.setState({
        animationShow: true
      });
    }, 300);

    axios.get(`${this.props.baseUrl}/content`)
      .then(res => {
        const freshApiData = res.data.content;

        this.setState({
          content: {
            headerImages: [
              freshApiData.about_full['over-ons_header_afb_1'].sizes['2048x2048'],
              freshApiData.about_full['over-ons_header_afb_2'].sizes['2048x2048'],
              freshApiData.about_full['over-ons_header_afb_3'].sizes['2048x2048'],
              freshApiData.about_full['over-ons_header_afb_4'].sizes['2048x2048'],
            ],
            "intro": freshApiData.about_full['over-ons_intro'],
            "left_column": freshApiData.about_full['over-ons_secundaire_intro_links'],
            "right_column": freshApiData.about_full['over-ons_secundaire_intro_rechts'],
            "blocks": freshApiData.about_full.blocks
          }
        });
      });
  }


  loadItem(item) {
    if(item.acf_fc_layout === 'quote') {
      if(item.volgorde_quote_en_afbeelding === "quote_right") {
        return (
          <div className="project-detail__quote project-detail__quote--about container-fluid">
            <div className="row align-items-center">
              <div className="col-md-5">
                <Parallax speed={this.setResponsiveValue(14, 3)} scale={[this.setResponsiveValue(0.8, 0.75), 1, [0, 1, 0.5, 1]]}>
                  <figure>
                    <img src={item['quote_afbeelding']['sizes']['2048x2048']} className="project-detail__quote-image" />
                    <div className="clickable-plus clickable-plus--right-bottom"><img src={plusIcon}/></div>
                  </figure>
                </Parallax>
              </div>
              <div className="col-md-5 offset-md-2">
                <Parallax speed={this.setResponsiveValue(5, 1)}>
                  <blockquote className="project-detail__quote-text">
                    {item.quote_quote}
                    <span className="project-detail__quote-author">{item.quote_auteur}</span>
                  </blockquote>
                </Parallax>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="project-detail__quote project-detail__quote--about container-fluid">
            <div className="row align-items-center">
              <div className="col-md-5 offset-md-1 order-md-1">
                <Parallax speed={this.setResponsiveValue(14, 3)} scale={[this.setResponsiveValue(0.8, 0.75), 1, [0, 1, 0.5, 1]]}>
                  <figure>
                    <img src={item['quote_afbeelding']['sizes']['2048x2048']} className="project-detail__quote-image" />
                    <div className="clickable-plus clickable-plus--right-bottom"><img src={plusIcon}/></div>
                  </figure>
                </Parallax>
              </div>
              <div className="col-md-5 offset-md-1 order-md-0">
                <Parallax speed={this.setResponsiveValue(5, 1)}>
                  <blockquote className="project-detail__quote-text">
                    {item.quote_quote}
                    <span className="project-detail__quote-author">{item.quote_auteur}</span>
                  </blockquote>
                </Parallax>
              </div>
            </div>
          </div>
        );
      }
    }

    if(item.acf_fc_layout === 'logos') {
      return (
        <div className="about__logos container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12">
              <Flickity
              className={'about__logos-slider'}
              elementType={'div'}
              options={this.state.flickityOptions}
              flickityRef={c => this.flkty = c}
              disableImagesLoaded={false} 
              reloadOnUpdate
              >
                {this.renderLogos.bind(this)(item.logos)}
              </Flickity>
            </div>
          </div>
        </div>
      );
    }
  }

  renderLogos(logos) {
    if(logos !== undefined && logos !== null) {
      return logos.map((logo, count) => {
        return (
          <div key={count}>
            <img src={logo.sizes['1536x1536']} />
          </div>
        )
      })
    }
  }

  setResponsiveValue(desktop, mobile) {
    if(window.innerWidth > 992) {
      return desktop;
    } else {
      return mobile;
    }
  }

  renderBlocks() {
    if(this.state.content !== undefined && this.state.content !== null && this.state.content.blocks !== undefined && this.state.content.blocks !== null) {
      return this.state.content.blocks.map((item, count) => {
        return (
          <div key={count}>
            {this.loadItem.bind(this)(item)}
          </div>
        )
      })
    }
  }

  renderHeaderImages() {
    if(this.state.content !== undefined && this.state.content !== null && this.state.content.headerImages !== undefined && this.state.content.headerImages !== null) {
      return this.state.content.headerImages.map((item, count) => {
        return (
          <div className="about__header-image" style={{backgroundImage: `url(${item})`}} key={count}>
          </div>
        )
      });
    }
  }

  render() {
    return (
      <div
        ref={ref => {
          this.pageContainer = ref
        }}
        className={`page-detail page-detail--padding ${(this.state.animationShow ? 'page-detail--show' : 'page-detail--hidden')}`}
      >
        <Helmet>
            <meta charSet="utf-8" />
            <title>About - Marco van Veldhuizen</title>
            <meta name="description" content="Marco van Veldhuizen is een jong ontwerpbureau met als uitgangspunt voor alle opdrachten het streven naar een totaalconcept, waardoor exterieur, interieur en tuinplan steeds in harmonie met elkaar zijn." />
        </Helmet>
        <ParallaxProvider>
          <main className="about__main">
            <div className="page-detail__title page-detail__title--about page-detail__title--border-bottom">
              <div className="about__slogan">
                <img src={slogan} />
                <img src={sloganMobile} />
              </div>
            </div>

            <div className="about__header-container">
              <Parallax translateX={[this.setResponsiveValue('-55%', '-55%'), '0']} shouldAlwaysCompleteAnimation>
                <div className="about__header">
                  {this.renderHeaderImages.bind(this)()}
                </div>
              </Parallax>
            </div>

            <div className="about__intro">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-8 offset-lg-1">
                    <p className="about__intro-main">{this.state.content.intro}</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-4 offset-lg-1">
                    <p className="about__intro-left" dangerouslySetInnerHTML={{__html: this.state.content.left_column}}></p>
                  </div>
                   <div className="col-lg-4 offset-lg-2">
                    <p className="about__intro-right" dangerouslySetInnerHTML={{__html: this.state.content.right_column}}></p>
                  </div>
                </div>
              </div>
            </div>

            {this.renderBlocks.bind(this)()}

          </main>
        </ParallaxProvider>
        <footer className="project-detail__footer">
          <div>
            <ul className="navigation__list navigation__list--no-animation">
              <li><Link to="/#start" data-label="&nbsp;View projects&nbsp;">View projects</Link></li>
              <li><Link to="/work-in-progress" data-label="&nbsp;Work in Progress&nbsp;">Work in Progress</Link></li>
              <li><Link to="/contact" data-label="&nbsp;Contact&nbsp;">Contact</Link></li>
            </ul>
            </div>

          <p className="project-detail__copyright">&copy; {this.copyrightYear()} Studio Marco van Veldhuizen</p>

          <div className="project-detail__footer-logo project-detail__footer-logo--padding">
            <img src={logo_l3} />
            <img src={logo_l2} />
            <img src={logo_l1} />
            <img src={logo_v} />
            <img src={logo_r1} />
            <img src={logo_r2} />
            <img src={logo_r3} />
          </div>
        </footer>
      </div>
    );
  }
}
