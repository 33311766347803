import React from 'react';
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import DOM from 'react-dom'

import HorizontalScroll from 'react-scroll-horizontal'
import axios from 'axios'
import Helmet from 'react-helmet';

import plusIcon from '../assets/img/plus.svg';

import textLogo from '../assets/img/text_logo.svg';
import slogan from '../assets/img/slogan.svg';
import logo from '../assets/img/logo.svg';
import facebook from '../assets/img/facebook.svg';
import instagram from '../assets/img/instagram.svg';


class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: {},
      slides: [],
      horizontalScroll: 0,
      scrollContainerWidth: 0,
      activeSlide: 0,
      playSlideAnimations: false,
      startOffset: 0,
      loadedWithOffset: false,
      showLockLayer: false,
      showLockLayerBack: false,
      outroContent: '<div></div>',
      apiData: {}
    }
  }

  componentDidMount() {
    document.querySelector('body').classList.add('dark-bg');
    document.querySelector('body').classList.remove('light-bg');

    document.querySelector('body').classList.add('is-home');

    axios.get(`${this.props.baseUrl}/content`)
      .then(res => {
        const freshApiData = res.data.content;

        this.setState({
          apiData: freshApiData
        });

        const slideData = [];

        freshApiData.projects.forEach((projectData) => {
          slideData.push({
            "title": projectData.post_title,
            "image": projectData.image.sizes['2048x2048'],
            "tagline": projectData.tagline,
            "cta": {
              "path": `/project/${projectData.post_name}`,
              "label": "View project"
            }
          });
        });

        this.setState({
          slides: slideData
        }, () => {
          // preload images
          this.state.slides.forEach((slideContent) => {
            const image = slideContent.image;

            const linkTag = document.createElement('link');
            linkTag.setAttribute('rel', 'preload');
            linkTag.setAttribute('as', 'image');
            linkTag.setAttribute('href', image);

            document.getElementsByTagName('head')[0].appendChild(linkTag);
          });

          // Update horizontal scroll offset state
          const container = this.setHorizontalScroll.bind(this)();

          // Center logo
          const logoElement = this.centerLogo.bind(this)();

          let didReset;

          if(window.location.hash !== undefined && window.location.hash !== null && window.location.hash === '#start') {
            didReset = this.resetScrollDepth(1);

            setTimeout(() => {
              document.querySelector('.home-scroll__layer').classList.add('home-scroll__layer--inactive');
            }, 0);

            setTimeout(() => {
              document.querySelector('.home-scroll__layer').classList.add('home-scroll__layer--inactive');
            }, 1000);
          } else {
            didReset = this.resetScrollDepth(0);
          }

          if(!didReset) {
            setTimeout(() => {
              document.querySelector('.home-scroll__layer').classList.add('home-scroll__layer--inactive');
            }, 0);
          }

          // Update container width state
          this.setContainerWidth.bind(this)();

          // Init slide animations after intro animation finished
          this.initSlideAnimations.bind(this)(2500, logoElement, didReset);
        });
      });

    setTimeout(() => {
      document.querySelector('.home-scroll__layer').classList.remove('home-scroll__layer--do-not-transition');
    }, 500);

    this.setState({
        outroContent: (localStorage.getItem('projectOutro') !== undefined && localStorage.getItem('projectOutro') !== null ? localStorage.getItem('projectOutro') : '<div></div>')
      }, () => {
        const scrollableDiv = DOM.findDOMNode(this.outroContent).querySelector('div');

        if(scrollableDiv !== undefined && scrollableDiv !== null) {
          scrollableDiv.scrollTop = localStorage.getItem('projectScrollOffset') * 1;
        }

        this.setState({
          showLockLayerBack: true
        });
        setTimeout(() => {
          const scrollableDiv = document.createElement('div');

          localStorage.setItem('projectOutro', scrollableDiv.outerHTML);
          localStorage.setItem('projectScrollOffset', 0);

          setTimeout(() => {
            this.setState({
              showLockLayerBack: false
            });
          }, 1000);
        }, 500);
      });
  }

  componentWillUnmount() {
    document.querySelector('body').classList.remove('is-home');
  }

  resetScrollDepth(depth) {
    const amount = (depth !== 0 ? depth : localStorage.getItem('scrollDepth'));

    if(amount !== null) {
      const number = amount * 1;

      if(number < -500) {
        this.setState({
          startOffset: number,
          horizontalScroll: number,
          loadedWithOffset: true
        }, () => {
          localStorage.setItem('scrollDepth', 0);

          setTimeout(() => {
            this.setHorizontalScroll.bind(this)();
            this.setContainerWidth.bind(this)();

            for(let i = 0; i < this.state.slides.length + 2; i++) {
              this.performImageTransformation.bind(this)(i, 0.5, 200);
            }
          }, 800);

          setTimeout(() => {
            document.querySelector('.home-scroll__layer').classList.add('home-scroll__layer--inactive');
          }, 1000);
        });

        return true;
      }
    }

    return false;
  }

  loadContent(object, path) {
    if(Object.keys(object).length === 0) {
      return 'Loading...';
    } else {
      const splittedPath = path.split('/');

      let nestedValue = JSON.parse(JSON.stringify(object));

      splittedPath.forEach((pathPart) => {
        nestedValue = nestedValue[pathPart];
      });

      nestedValue = nestedValue.split('\r\n').join('<br/>');

      return nestedValue;
    }  
  }

  centerLogo() {
    const logo = DOM.findDOMNode(this.logo);
    const logoSVG = logo.querySelector('svg');
    const widthDiff = logo.getBoundingClientRect().width - logoSVG.getBoundingClientRect().width;

    logoSVG.style.transform = 'translate3d(' + (widthDiff / 2) + 'px, 0, 0)';
    logo.style.opacity = 1;

    return logoSVG;
  }

  setHorizontalScroll() {
    const scrollContainerNode = DOM.findDOMNode(this.scrollContainer);

    let oldOffset = 0;
    let needsUpdate = false;

    if(navigator.userAgent.toLowerCase().indexOf('firefox') < 0){
      scrollContainerNode.addEventListener('wheel', (e) => {
        const translateIndex = window.homeTranslate;

        if(((translateIndex + 4) < oldOffset) || ((translateIndex - 4) > oldOffset)) {
          needsUpdate = true;
          oldOffset = translateIndex;
        } else {
          needsUpdate = false;
        }

        if(needsUpdate) {
          this.setState({
            horizontalScroll: (window.innerWidth < 767 ? 0 : translateIndex)
          }, () => {
            setTimeout(() => {
              this.setState({
                horizontalScroll: (window.innerWidth < 767 ? 0 : translateIndex)
              });
            }, 250);

            setTimeout(() => {
              this.setState({
                horizontalScroll: (window.innerWidth < 767 ? 0 : translateIndex)
              });
            }, 500);

            // Set active slide (integer, first = 0) state
            this.setActiveSlide.bind(this)(window.homeTranslate, 20)
              .then(() => {
                const currentSlide = this.state.activeSlide;


                // transitionStart = 0.5 (transition start at 50% of previous slide)
                // absoluteTransform = 200 (transition image transform translates 200px)
                const aniFunction = this.performImageTransformation.bind(this, currentSlide, 0.5, 200);
                requestAnimationFrame(aniFunction);
              });
          });
        }
      }, {passive: true});
    }

    return scrollContainerNode;
  }

  performImageTransformation(currentSlide, transitionStart, absoluteTransform) {
    const current = currentSlide;
    const next = current + 1;
    const containerWidth = this.state.scrollContainerWidth;

    const positiveScrollOffset = window.homeTranslate * -1;
    let positiveScrollOffsetMaxxed = positiveScrollOffset;

    if(document.querySelector('.home-scroll__intent') !== null) {
      if(positiveScrollOffset > 80) {
        if(!document.querySelector('.home-scroll__intent').classList.contains('hidden')) {
          document.querySelector('.home-scroll__intent').classList.add('hidden');
        }
      } else {
       if(document.querySelector('.home-scroll__intent').classList.contains('hidden')) {
          document.querySelector('.home-scroll__intent').classList.remove('hidden');
        }
      }
    }

    const firstSlideWidth = 0.6 * containerWidth;
    const startOffset = ((current - 1) * containerWidth) + firstSlideWidth;
    const endOffset = ((current - 1) * containerWidth) + containerWidth + firstSlideWidth;

    if(positiveScrollOffsetMaxxed > endOffset) {
      positiveScrollOffsetMaxxed = endOffset;
    }

    const relativeScroll = positiveScrollOffset - startOffset;
    const relativeScrollMaxxed = positiveScrollOffsetMaxxed - startOffset;

    const startPercentage = 1 * transitionStart;

    let relativePerCentScroll = Math.round((relativeScroll / containerWidth) * 1000) / 1000;
    let relativePerCentScrollMaxxed = Math.round((relativeScrollMaxxed / containerWidth) * 1000) / 1000;

    if(relativePerCentScroll <= 0) {
      relativePerCentScroll = 0;
    }

    if(relativePerCentScrollMaxxed <= 0) {
      relativePerCentScrollMaxxed = 0;
    }

    let transitionOffset = 0;

    if((relativePerCentScroll - startPercentage) >= 0) {
      transitionOffset = (relativePerCentScrollMaxxed - startPercentage) * (1 / transitionStart);
    }

    const slide = document.querySelector('[data-slide-count=\'' + (current + 2) + '\']');
    const slidePrev = document.querySelector('[data-slide-count=\'' + (current + 1) + '\']');

    // Triangle
    const triangle = slidePrev.querySelector('.home-scroll__triangle div');
    const defaultTriangleRotation = 7 / ((currentSlide * 1) === 0 ? 0.6 : 1);
    const triangleTransformationRotation = defaultTriangleRotation - (defaultTriangleRotation / (1 / relativePerCentScroll));

    if(triangle !== null) {
      triangle.style.transform = 'translateY(-50%) rotate(' + triangleTransformationRotation + 'deg)';
    }
     // End of triangle


     // Image zoom
     const zoomLevel = (relativePerCentScrollMaxxed / 0.1) + 1;
     const image = slide.querySelector('.home-scroll__project-image figure');

      if(image !== null) {
        image.style.transform = 'scale(' + '1.2' + ') translate3d(' + (zoomLevel * 1) + '%, 0, 0)';
      }
     // End of image zoom

     // Transform content
     const translatePercentage = Math.ceil(10000 * (1 - transitionOffset)) / 10000;

     if((slide.querySelector('.home-scroll__project-title') !== null) && (slide.querySelector('.home-scroll__project-tagline') !== null)) {
       slide.querySelector('.home-scroll__project-title').style.transform = 'translate3d(' + (translatePercentage * 100) + 'px, 0, 0)';
       slide.querySelector('.home-scroll__project-tagline').style.transform = 'translate3d(' + (translatePercentage * 100) + 'px, 0, 0)';
       slide.querySelector('.home-scroll__project-cta').style.transform = 'translate3d(' + (translatePercentage * 200) + 'px, 0, 0)';
      }
     // End of transform content

  }

  setContainerWidth() {
    const performAction = () => {
      const container = window;

      this.setState({
        scrollContainerWidth: container.innerWidth
      }, () => {
        // console.log('this.state.scrollContainerWidth', this.state.scrollContainerWidth);
      });
    };

    performAction();

    window.addEventListener('resize', () => {
      performAction();
    });
  }

  initSlideAnimations(duration, logoElement, didReset) {
    setTimeout(() => {
      this.setState({
        playSlideAnimations: true
      }, () => {
        const image = document.querySelector('[data-slide-count=\'2\'] .home-scroll__project-image figure');

        if(!didReset) {
          image.style.transition = 'transform 1.7s 0.2s ease-in-out';
          image.style.transform = 'scale(1.2) translate3d(5%, 0, 0)';
        }

        setTimeout(() => {
          image.style.transition = 'transform 0.2s linear';
        }, 2000);

        setTimeout(() => {
          document.querySelector('.home-scroll__intro-animation').classList.add('home-scroll__intro-animation--active')
          document.querySelector('body').classList.add('intro-animation-active')
        }, 100);

        logoElement.style.transition = 'transform 1.7s ease-in-out';
        logoElement.style.transform = 'none';
      });
    }, duration);    
  }

  async setActiveSlide(scrollOffset, offset) {
    this.setState({
      activeSlide: this.getActiveSlide.bind(this)(scrollOffset, offset)
    }, () => {
      return this.state.activeSlide;
    });
  }

  getActiveSlide(scrollOffset, offset) {
    const positiveScrollOffset = scrollOffset * -1;
    const offsetToCalculateWith = positiveScrollOffset + 1 + offset;

    const containerWidth = this.state.scrollContainerWidth;
    const firstSlideWidth = containerWidth * 0.6;

    if(positiveScrollOffset >= 0) {
      const slideIndex = Math.floor((offsetToCalculateWith + (containerWidth - firstSlideWidth)) / this.state.scrollContainerWidth);

      return slideIndex;
    }
    
    return 0;
  }

  closestFullSlide(horizontalScroll, scrollDepth) {
    return (horizontalScroll - scrollDepth) * -1;
  }

  preLoadData(slug) {
    axios.get(`${this.props.baseUrl}/project?project_slug=${slug}`)
      .then(res => {
        this.props.setProjectPreloadedData(res.data);
      });
  }
  
  copyrightYear() {
    const today = new Date();

    return today.getFullYear();
  }

  openProject(path, slide) {
    this.preLoadData(path.replace('/project/', ''));
    
    let containerWidth = this.state.scrollContainerWidth;

    let firstSlideWidth = 0.6 * containerWidth;
    let startOffset = ((slide - 1) * containerWidth) + firstSlideWidth;

    let scrollDepth = startOffset * -1;

    this.setState({
      startOffset: this.closestFullSlide(window.homeTranslate, scrollDepth)
    }, () => {
      this.openPage(path, true, (window.homeTranslate + this.state.startOffset));

      setTimeout(() => {
        containerWidth = this.state.scrollContainerWidth;

        firstSlideWidth = 0.6 * containerWidth;
        startOffset = ((slide - 1) * containerWidth) + firstSlideWidth;

        scrollDepth = startOffset * -1;

        const marginLeft = scrollDepth - (document.querySelector('.scroll-horizontal > div').style.transform.replace('translate3d(', '').split(',')[0].replace('px', '') * 1);

        const slideDOM = document.querySelector('[data-slide-count=\'' + (slide + 1) + '\']');
        slideDOM.classList.add('home-scroll__project--active');

        const bootstrapContainerWidth = this.state.scrollContainerWidth - (65 * 2) - (4 * 2);
        const bootstrapColumnWidth = (bootstrapContainerWidth * (33.33333333 / 100));
        const bootstrapOffsetWidth = (bootstrapContainerWidth * (8.33333333 / 100)) + 12 + 4 + 65; // offset needs to be this from left
        const imageWidth = bootstrapColumnWidth - (12 * 2); // image need to be this wide

        const image = slideDOM.querySelector('.home-scroll__project-image figure');

        image.style.maxWidth = imageWidth + 'px';
        image.style.transform = 'translate3d(' + (Math.ceil(((marginLeft * 1) + bootstrapOffsetWidth) * 100) / 100) + 'px, ' + (window.innerHeight * (15 / 100)) + 'px, 0) scale(1)';

        const rightContainerBorder = containerWidth - ((containerWidth - bootstrapContainerWidth) / 2) - 12;
        const rightOffsetRight = containerWidth - rightContainerBorder;

        const title = slideDOM.querySelector('.home-scroll__project-title');
        const tagline = slideDOM.querySelector('.home-scroll__project-tagline');

        const existingTitleTransform = title.style.transform;
        const existingTitleTransformX = existingTitleTransform.replace('translate3d(', '').split(',')[0].replace('px', '') * 1;
        
        const titleOffsetRightSide = containerWidth - title.getBoundingClientRect().right;

        const titleOffsetDiff = (rightOffsetRight - titleOffsetRightSide - existingTitleTransformX) * -1;

        title.style.transform = 'translate3d(' + titleOffsetDiff + 'px, 0, 0)';
        tagline.style.transform = 'translate3d(' + titleOffsetDiff + 'px, 0, 0)';
      }, (window.innerWidth < 992 ? 0 : 750));
    });
  }

  openPage(path, isProject, memory) {
    this.setState({
      showLockLayer: true
    });

    this.setCurrentScrollOffset(memory);

    document.querySelector('.home-scroll__layer').outerHTML = '';

    const timeToPageSwitch = (isProject ? (window.innerWidth < 992 ? 500 : 2750) : 400);

    if(!isProject) {
      if(document.querySelector('.home-scroll__layer') !== null) {
        document.querySelector('.home-scroll__layer').classList.remove('home-scroll__layer--inactive');
      }
    } else {
      setTimeout(() => {
        const projectLayers = document.querySelectorAll('.home-scroll__project-background-layer');

        projectLayers.forEach((item) => {
          item.classList.add('home-scroll__project-background-layer--show');
        });

        const backgrounds = document.querySelectorAll('.home-scroll__project-content, .home-scroll__triangle > div');

        backgrounds.forEach((item) => {
          item.classList.add('color-light');
        });

        document.querySelector('body').classList.add('color-light');
      }, 750);
    }

    setTimeout(() => {
      this.props.navigate(path);
    }, timeToPageSwitch);
  }

  setCurrentScrollOffset(memory) {
    const scrollDepth = ((memory !== undefined) && (memory !== null) && (memory !== 0) ? memory : window.homeTranslate);

    localStorage.setItem('scrollDepth', scrollDepth);
  }

  renderSlides() {
    const slides = this.state.slides;

    if(slides === null || slides.length < 1) {
      return false;
    }

    return slides.map((slide, count) => {
      return (
        <div
          className={`${(count === (slides.length - 1) ? 'home-scroll__project--last' : '')} ${this.displayProjectClassName('home-scroll__project', (count === (this.state.activeSlide - 1)), (count === this.state.activeSlide))}`}
          key={count}
          data-slide-count={count + 2}
        >
          <div className="home-scroll__project-container">
            <div className="home-scroll__project-image--clickable" onClick={this.openProject.bind(this, slide.cta.path, (count + 1))}>
            </div>
            <div className="home-scroll__project-image">
              <figure style={{backgroundImage: `url(${slide.image})`}} onClick={() => {
                if(window.innerWidth < 992) {
                  this.openProject(slide.cta.path, (count + 1))
                }
              }}></figure>

              <div className="clickable-plus clickable-plus--right-bottom"><img src={plusIcon}/></div>
            </div>
            <div className="home-scroll__project-content">
              <div className="home-scroll__project-background-layer"></div>
              <div className="home-scroll__project-inner-container">
                <h1 className="home-scroll__project-title" onClick={() => {
                  if(window.innerWidth < 992) {
                    this.openProject(slide.cta.path, (count + 1))
                  }
                }}>{slide.title}</h1>
                <h4 className="home-scroll__project-tagline" onClick={() => {
                  if(window.innerWidth < 992) {
                    this.openProject(slide.cta.path, (count + 1))
                  }
                }}>{slide.tagline}</h4>

                <button className="button home-scroll__project-cta" onClick={this.openProject.bind(this, slide.cta.path, (count + 1))}>{slide.cta.label}</button>
              </div>
            </div>
          </div>

          <div className="home-scroll__triangle"><div></div></div>
        </div>
      );
    });
  }

  displayProjectClassName(className, isActive, isPrev) {
    return `${className} ${(isActive ? `${className}--current` : '')} ${(isPrev ? `${className}--prev` : '')}`;
  }

  footerLinks(data) {
    if(Object.keys(data).length > 0) {
      const links = data.footer.footer_links;

      return links.map((link, count) => {
        if(!link.footer_links_link.url || !link.footer_links_link.title) {
          return;
        }

        if(link.footer_links_link.url.indexOf('https://') > -1) {
          return (
            <li key={count} className="copyright__item"><a href={link.footer_links_link.url}  target={link.footer_links_link.url.indexOf('.pdf') > -1 ? '_blank' : '_self'}>{link.footer_links_link.title}</a></li>
          );
        } else {
          return (
            <li key={count} className="copyright__item"><Link to={link.footer_links_link.url}  target={link.footer_links_link.url.indexOf('.pdf') > -1 ? '_blank' : '_self'}>{link.footer_links_link.title}</Link></li>
          );

        }
       
      });
    }
  }

  render() {
    const slidesOutput = this.renderSlides.bind(this)();

    return ( 
      <div className={`home-scroll ${(this.state.showLockLayerBack ? 'home-scroll--locked' : '')} ${(this.state.showLockLayer ? 'home-scroll--locked-front' : '')} ${(this.state.loadedWithOffset ? 'home-scroll--skip-animations' : 'home-scroll--not-skip-animations')} ${(this.state.playSlideAnimations ? 'home-scroll--play-animations' : 'home-scroll--not-play-animations')}`}>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Home - Marco van Veldhuizen</title>
            <meta name="description" content="Marco van Veldhuizen is een jong ontwerpbureau met als uitgangspunt voor alle opdrachten het streven naar een totaalconcept, waardoor exterieur, interieur en tuinplan steeds in harmonie met elkaar zijn." />
        </Helmet>
        {((this.state.showLockLayer || this.state.showLockLayerBack) &&
          <div className="home-scroll__lock-layer">
          </div>
        )}
        <HorizontalScroll
          pageLock={true}
          reverseScroll={true}
          animValues={this.state.startOffset}
          ref={ref => {
            this.scrollContainer = ref
          }}
        >
          <div className="home-scroll__intro" data-slide-count="1">
            <div className="home-scroll__project-background-layer"></div>
            <div className="home-scroll__intro-animation">
              <div className="home-scroll__intent">
                <div>Scroll&nbsp;&nbsp;Scroll&nbsp;&nbsp;Scroll&nbsp;&nbsp;Scroll&nbsp;&nbsp;Scroll&nbsp;&nbsp;Scroll</div>
              </div>
              <img src={slogan} className="home-scroll__slogan" />

              {this.loadContent(this.state.apiData, 'home/home_temp_text') !== '' &&
                <p className="home-scroll__temp-text">{this.loadContent(this.state.apiData, 'home/home_temp_text')}</p>
              }

              <div className="home-scroll__logo"  ref={ref => {
                this.logo = ref
              }}>
                <svg width="279px" height="139px" viewBox="0 0 279 139" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="home-scroll__logo-svg">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-594.000000, -289.000000)" fill="#F2D4B6">
                      <g transform="translate(594.000000, 289.000000)">
                        <g>
                          <polygon id="left-1" points="8.8817842e-15 138.82 13.105 138.82 13.105 8.52651283e-14 8.8817842e-15 8.52651283e-14"></polygon>
                          <polygon id="right-1" points="265.21 138.82 278.315 138.82 278.315 8.52651283e-14 265.21 8.52651283e-14"></polygon>
                          <polygon id="left-2" points="56.977 138.822 39.122 0.002 25.902 0.002 43.757 138.822"></polygon>
                          <polygon id="left-3" points="101.2445 138.822 64.9245 0.002 51.3795 0.002 87.6945 138.822"></polygon>
                          <polygon id="left-4" points="146.264 138.822 90.174 0.002 76.044 0.002 132.129 138.822"></polygon>
                          <polygon id="right-4" points="146.186 138.822 202.271 0.002 188.141 0.002 132.051 138.822"></polygon>
                          <polygon id="right-3" points="190.6195 138.822 226.9345 0.002 213.3895 0.002 177.0695 138.822"></polygon>
                          <polygon id="right-2" points="234.55 138.822 252.41 0.002 239.195 0.002 221.335 138.822"></polygon>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>

            <div className="home-scroll__triangle"><div></div></div>
          </div>
          
          {(slidesOutput !== false &&
            slidesOutput
          )}

          <div className="home-scroll__content-slide" data-slide-count={this.state.slides.length + 2}>
            <div className="home-scroll__project-background-layer"></div>

            <div className="home-scroll__centered-content">
              <h4 className="home-scroll__content-title">{this.loadContent(this.state.apiData, 'about/over_ons_titel')}</h4>

              <blockquote className="home-scroll__content-quote">{this.loadContent(this.state.apiData, 'about/over_ons_introtekst')}</blockquote>
              <button onClick={this.openPage.bind(this, '/about', false, 0)} className="button button--light-desktop">{this.loadContent(this.state.apiData, 'about/over_ons_knop_label')}</button>
            </div>
          </div>

          <div className="home-scroll__content-slide" data-slide-count={this.state.slides.length + 3}>
            <div className="home-scroll__centered-content home-scroll__centered-content--spaced">
              <h4 className="home-scroll__content-title home-scroll__content-title--hidden-desktop">{this.loadContent(this.state.apiData, 'contact/contact_titel')}</h4>

              <div className="contact-table">
                <div className="contact-table__row">
                  <div className="contact-table__title">{this.loadContent(this.state.apiData, 'contact/contact_telefoon_label')}</div>
                  <div className="contact-table__value"><a href={this.loadContent(this.state.apiData, 'contact/contact_telefoon_link')}>{this.loadContent(this.state.apiData, 'contact/contact_telefoon_waarde')}</a></div>
                </div>

                 <div className="contact-table__row">
                  <div className="contact-table__title">{this.loadContent(this.state.apiData, 'contact/contact_email_label')}</div>
                  <div className="contact-table__value"><a href={this.loadContent(this.state.apiData, 'contact/contact_email_link')}>{this.loadContent(this.state.apiData, 'contact/contact_email_waarde')}</a></div>
                </div>

                 <div className="contact-table__row">
                  <div className="contact-table__title">{this.loadContent(this.state.apiData, 'contact/contact_adres_label')}</div>
                  <div className="contact-table__value contact-table__value--has-button">
                    <div dangerouslySetInnerHTML={{__html: this.loadContent(this.state.apiData, 'contact/contact_adres_waarde')}}></div>
                    <a href={this.loadContent(this.state.apiData, 'contact/contact_adres_link')} target="_blank" className="button button--wide button--light-desktop">Route</a>
                  </div>
                </div>
              </div>

              <div className="social-container">
                <div className="social-container__media">
                  <ul>
                    <li>
                      <a href={this.loadContent(this.state.apiData, 'contact/contact_facebook_link')} target="_blank">
                        <img src={facebook} />
                        <span>Facebook</span>
                      </a>
                    </li>
                    <li>
                      <a href={this.loadContent(this.state.apiData, 'contact/contact_instagram_link')} target="_blank">
                        <img src={instagram} />
                        <span>Instagram</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="social-container__logo">
                  <img src={logo} />
                </div>
              </div>


              <div className="copyright">
                <ul className="copyright__list">
                  <li className="copyright__item">&copy; {this.copyrightYear()} Studio Marco van Veldhuizen</li>
                  {this.footerLinks(this.state.apiData)}
                </ul>
              </div>
            </div>
          </div>
          
        </HorizontalScroll>

        <div
          className="home-scroll__layer home-scroll__layer--do-not-transition"
          dangerouslySetInnerHTML={{__html: this.state.outroContent}}
          ref={ref => {
            this.outroContent = ref
          }}
        ></div>
      </div>
    );
  }
}

function withNavigation(Component) {
  return props => <Component {...props} navigate={useNavigate()} />;
}

export default withNavigation(Home);
