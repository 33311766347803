import React from 'react';

import axios from 'axios'
import { Routes, Route, Link } from "react-router-dom";
import './assets/scss/main.scss';

import textLogo from './assets/img/text_logo.svg';
import textLogoDark from './assets/img/text_logo-dark.svg';

import Home from "./partials/Home.jsx";
import About from "./partials/About.jsx";
import Project from "./partials/Project.jsx";
import Contact from "./partials/Contact.jsx";
import WorkInProgress from "./partials/WorkInProgress.jsx";
import Jobs from "./partials/Jobs.jsx";

import logo from './assets/img/logo.svg';
import facebook from './assets/img/facebook.svg';
import instagram from './assets/img/instagram.svg';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.baseUrl = 'https://marcovanveldhuizen.nl/cms/wp-json/mvv/v1';
    // this.baseUrl = 'http://mvv.local/wp-json/mvv/v1';

    this.state = {
      activeMenu: false,
      disableMenu: false,
      preLoadedData: {},
      apiData: {}
    };
  }

  toggleMenu(e) {
    if(e !== undefined) {
      e.preventDefault();
    }

    this.setState({
      activeMenu: !this.state.activeMenu,
      disableMenu: false
    })
  }

  setProjectPreloadedData(data) {
    this.setState({
      preLoadedData: data
    });
  }

  componentDidMount() {
    const isHover = e => e.parentElement.querySelector(':hover') === e;    

    axios.get(`${this.baseUrl}/content`)
      .then(res => {
        const freshApiData = res.data.content;

        this.setState({
          apiData: freshApiData
        });
      });

    (function() {
      document.onmousemove = handleMouseMove;
      function handleMouseMove(event) {
          let eventDoc, doc, body;

          event = event || window.event; // IE-ism

          if (event.pageX == null && event.clientX != null) {
              eventDoc = (event.target && event.target.ownerDocument) || document;
              doc = eventDoc.documentElement;
              body = eventDoc.body;

              event.pageX = event.clientX +
                (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
                (doc && doc.clientLeft || body && body.clientLeft || 0);
              event.pageY = event.clientY +
                (doc && doc.scrollTop  || body && body.scrollTop  || 0) -
                (doc && doc.clientTop  || body && body.clientTop  || 0 );
          }

          let root = document.documentElement;

          root.style.setProperty('--mouse-x', event.pageX + "px");
          root.style.setProperty('--mouse-y', (event.pageY - window.scrollY) + "px");

          const links = document.querySelectorAll('a, button, .header__hamburger, .slider-open-photo, .project-detail__next-button');
          let isActive = false;
          let isPlus = false;
          let isArrow = false;

          links.forEach((link) => {
            if(isHover(link)) {
              isActive = true;

              if(link.classList.contains('slider-open-photo')) {
                isPlus = true;
              }

              if(link.classList.contains('project-detail__next-button')) {
                isArrow = true;
              }
            }
          });


          if (isActive) {
            if(isPlus || isArrow) {
              root.style.setProperty('--cursor-width', "40px");
              root.style.setProperty('--cursor-height', "40px");

              if(isPlus) {
                document.querySelector('.custom-cursor-dot').classList.add('plus-icon');
              }

              if(isArrow) {
                document.querySelector('.custom-cursor-dot').classList.add('arrow-icon');
              }
            } else {
              root.style.setProperty('--cursor-width', "30px");
              root.style.setProperty('--cursor-height', "30px");
            }
          } else {
            root.style.setProperty('--cursor-width', "15px");
            root.style.setProperty('--cursor-height', "15px");

            document.querySelector('.custom-cursor-dot').classList.remove('plus-icon');
            document.querySelector('.custom-cursor-dot').classList.remove('arrow-icon');
          }
      }
    })();
  }

  footerLinks(data) {
    if(Object.keys(data).length > 0) {
      const links = data.footer.footer_links;

      return links.map((link, count) => {
        if(!link.footer_links_link.url || !link.footer_links_link.title) {
          return;
        }

        if(link.footer_links_link.url.indexOf('https://') > -1) {
          return (
            <li key={count} className=""><a href={link.footer_links_link.url}  target={link.footer_links_link.url.indexOf('.pdf') > -1 ? '_blank' : '_self'}>{link.footer_links_link.title}</a></li>
          );
        } else {
          return (
            <li key={count} className=""><Link to={link.footer_links_link.url}  onClick={this.closeMenu.bind(this)}>{link.footer_links_link.title}</Link></li>
          );
        }
       
      });
    }
  }

  closeMenu(e) {
    this.setState({
      disableMenu: true
    });

    setTimeout(() => {
      this.toggleMenu.bind(this)();
    }, 1600);
  }

  render() {
    return (
      <div className={`mvv ${(this.state.activeMenu ? 'active-menu' : '')}`}>
        <div className="header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <Link to="/">
                  <figure className={`home-scroll__text-logo-container ${(this.state.activeMenu ? 'home-scroll__text-logo-container--hidden' : '')}`}>
                    <img src={textLogo} className="home-scroll__text-logo home-scroll__text-logo--light" />
                    <img src={textLogoDark} className="home-scroll__text-logo home-scroll__text-logo--dark" />
                  </figure>
                </Link>
              </div>
              <div className="col-md-6">
                <div className={`header__hamburger ${(this.state.activeMenu ? 'header__hamburger--active' : '')}`} onClick={this.toggleMenu.bind(this)}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`navigation ${(this.state.disableMenu ? 'navigation--disabled' : '')} ${(this.state.activeMenu ? 'navigation--active' : '')}`}>
          <div className="navigation__list-container">
            <ul className="navigation__list">
              <li><Link to="/#start" data-label="&nbsp;Home&nbsp;" onClick={this.closeMenu.bind(this)}>Home</Link></li>
              <li><Link to="/about" data-label="&nbsp;About&nbsp;" onClick={this.closeMenu.bind(this)}>About</Link></li>
              <li><Link to="/work-in-progress" data-label="&nbsp;Work in Progress&nbsp;" onClick={this.closeMenu.bind(this)}>Work in Progress</Link></li>
              <li><Link to="/contact" data-label="&nbsp;Contact&nbsp;" onClick={this.closeMenu.bind(this)}>Contact</Link></li>
            </ul>
          </div>

          <div className="social-container">
            <div className="social-container__media">
              <ul>
                <li>
                  <a href="https://www.facebook.com/MarcovanVeldhuizenArchitecten" target="_blank">
                    <img src={facebook} />
                    <span>Facebook</span>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/studio_marcovanveldhuizen/" target="_blank">
                    <img src={instagram} />
                    <span>Instagram</span>
                  </a>
                </li>
              </ul>

              <ul className="navigation__extra-list">
                {this.footerLinks(this.state.apiData)}
              </ul>
            </div>
            <div className="social-container__logo">
              <img src={logo} />
            </div>
          </div>

          <div className="navigation__layer"></div>
        </div>

        <div className="custom-cursor-dot"><div></div></div>

        <Routes>
          <Route
            path="/"
            element={
              <Home
                baseUrl={this.baseUrl}
                setProjectPreloadedData={this.setProjectPreloadedData.bind(this)}
              />
            }
          />
          <Route path="/about" element={<About baseUrl={this.baseUrl} />} />
          <Route path="/contact" element={<Contact baseUrl={this.baseUrl} />} />
          <Route path="/work-in-progress" element={<WorkInProgress baseUrl={this.baseUrl} />} />
          <Route path="/jobs" element={<Jobs baseUrl={this.baseUrl} />} />
          <Route
            path="/project/:project_slug"
            element={
              <Project
                baseUrl={this.baseUrl}
                preLoadedContent={this.state.preLoadedData}
              />
            }
          />
        </Routes>
      </div>
    );
  }
}

export default App;
