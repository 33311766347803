import React from 'react';
import DOM from 'react-dom'

import { Link } from "react-router-dom";
import Flickity from 'react-flickity-component';
import axios from 'axios';

import logo_l1 from '../assets/img/logo/l1.svg';
import logo_l2 from '../assets/img/logo/l2.svg';
import logo_l3 from '../assets/img/logo/l3.svg';
import logo_r1 from '../assets/img/logo/r1.svg';
import logo_r2 from '../assets/img/logo/r2.svg';
import logo_r3 from '../assets/img/logo/r3.svg';
import logo_v from '../assets/img/logo/v.svg';

import Helmet from 'react-helmet';

export default class Jobs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      animationShow: false,
      content: [],
      flickityOptions: {
      	freeScroll: true,
      	cellAlign: 'left',
      	contain: true,
      	pageDots: false,
      	arrowShape: "M47.5269119,11.2720779 L53.1837662,16.9289322 C53.5742905,17.3194565 53.5742905,17.9526215 53.1837662,18.3431458 L27.071,44.455039 L109,44.4558441 C109.552285,44.4558441 110,44.9035594 110,45.4558441 L110,53.4558441 C110,54.0081289 109.552285,54.4558441 109,54.4558441 L27.071,54.455039 L53.1837662,80.5685425 C53.5742905,80.9590668 53.5742905,81.5922318 53.1837662,81.9827561 L47.5269119,87.6396103 C47.1363876,88.0301346 46.5032227,88.0301346 46.1126984,87.6396103 L14.3053057,55.8320077 C14.3011417,55.8279834 14.297004,55.8239159 14.2928932,55.8198052 L12.928,54.455039 L11,54.4558441 C10.4477153,54.4558441 10,54.0081289 10,53.4558441 L10,51.525039 L8.63603897,50.1629509 C8.24551468,49.7724266 8.24551468,49.1392616 8.63603897,48.7487373 L10,47.385039 L10,45.4558441 C10,44.9035594 10.4477153,44.4558441 11,44.4558441 L12.928,44.455039 L14.2928932,43.0918831 L14.308,43.077039 L46.1126984,11.2720779 C46.5032227,10.8815536 47.1363876,10.8815536 47.5269119,11.2720779 Z"
      }
    }
  }

  componentWillUnmount() {
    const pageContainer = DOM.findDOMNode(this.pageContainer);

    localStorage.setItem('projectOutro', pageContainer.outerHTML);
    localStorage.setItem('projectScrollOffset', 0);
  }

  copyrightYear() {
    const today = new Date();

    return today.getFullYear();
  }

  jobLinks(data) {
    if(Object.keys(data).length > 0) {
      const links = data;

      return links.map((link, count) => {
        return (
          <li  key={count}>{link.item}</li>
        );
      });
    }
  }

  componentDidMount() {
    document.querySelector('body').classList.add('light-bg');
    document.querySelector('body').classList.remove('dark-bg');

    window.scrollTo(0, 0);

    setTimeout(() => {
      this.setState({
        animationShow: true
      });
    }, 300);

    axios.get(`${this.props.baseUrl}/content`)
      .then(res => {
        const freshApiData = res.data.content;

        this.setState({
          content: freshApiData.jobs
        });
      });
  }

  renderJobs() {
  	if(this.state.content !== undefined && this.state.content !== null  && this.state.content.length > 0) {
  		return this.state.content.map((item, count) => {
  			return (
  				<div className="page-detail__job" data-job-name={item.post_name} key={count}>
  					<h4 className="page-detail__job-title">{item.post_title}</h4>

            <p className="page-detail__job-intro" dangerouslySetInnerHTML={{__html: item.vacature_intro}}></p>

            <div className="row page-detail__job-row">
              <div className="col-md-5">
                <p className="page-detail__job-subtitle" dangerouslySetInnerHTML={{__html: item.vacature_functieomschrijving_titel}}></p>
                <p className="page-detail__job-text" dangerouslySetInnerHTML={{__html: item.vacature_functieomschrijving}}></p>

              </div>
              <div className="col-md-6 offset-md-1">
                <p className="page-detail__job-subtitle" dangerouslySetInnerHTML={{__html: item.vacature_takenpakket_titel}}></p>

                <ul className="page-detail__job-list">
                  {this.jobLinks(item.vacature_takenpakket_items)}
                </ul>
              </div>
            </div>

            <p className="page-detail__job-subtitle" dangerouslySetInnerHTML={{__html: item.vacature_profiel_titel}}></p>

            <ul className="page-detail__job-list">
            {this.jobLinks(item.vacature_profiel_items)}
            </ul>

            <p className="page-detail__job-subtitle" dangerouslySetInnerHTML={{__html: item.vacature_footer_titel}}></p>
            <p className="page-detail__job-text" dangerouslySetInnerHTML={{__html: item.vacature_footer_tekst}}></p>
          
            <a className="button page-detail__job-button" href={item.vacature_call_to_action}>Solliciteer</a>
  				</div>
  			);
  		});
  	}

    return false;
  }

  goToJob(name) {
    const job = document.querySelector('[data-job-name="' + name + '"]');

    job.scrollIntoView({
      behavior: "smooth",
      inline: "start"
    });
  }

  renderJobLinks() {
  	if(this.state.content !== undefined && this.state.content !== null  && this.state.content.length > 0) {
  		return this.state.content.map((item, count) => {
  			return (
  				<li key={count}>
  				  <a href="#" onClick={() => this.goToJob(item.post_name)}>{item.post_title}</a>
          </li>
  			);
  		});
  	}

    return false;
  }

  render() {
    return (
      <div
        ref={ref => {
          this.pageContainer = ref
        }}
        className={`page-detail page-detail--padding ${(this.state.animationShow ? 'page-detail--show' : 'page-detail--hidden')}`}
      >
        <Helmet>
            <meta charSet="utf-8" />
            <title>Vacatures - Marco van Veldhuizen</title>
            <meta name="description" content="Marco van Veldhuizen is een jong ontwerpbureau met als uitgangspunt voor alle opdrachten het streven naar een totaalconcept, waardoor exterieur, interieur en tuinplan steeds in harmonie met elkaar zijn." />
        </Helmet>
        <main>
      		<div className="page-detail__title">
      			<h1>Vacatures</h1>
      		</div>

          {!this.renderJobs.bind(this)() ?
            <div className="page-detail__jobs">
              <p className="page-detail__subtitle">Er zijn momenteel geen vacatures.</p>
            </div>
          :
          <div className="page-detail__jobs">
            <div className="row">
              <div className="col-md-3">
                <ul className="page-detail__job-menu">
                {this.renderJobLinks.bind(this)()}
                </ul>
              </div>
              <div className="col-md-9">
                {this.renderJobs.bind(this)()}
              </div>
            </div>
          </div>
          }
     
        </main>
    	<footer className="project-detail__footer">
    		<div>
				<ul className="navigation__list navigation__list--no-animation">
					<li><Link to="/#start" data-label="&nbsp;View projects&nbsp;">View projects</Link></li>
					<li><Link to="/about" data-label="&nbsp;About&nbsp;">About</Link></li>
					<li><Link to="/contact" data-label="&nbsp;Contact&nbsp;">Contact</Link></li>
				</ul>
    		</div>

			<p className="project-detail__copyright">&copy; {this.copyrightYear()} Studio Marco van Veldhuizen</p>

			<div className="project-detail__footer-logo project-detail__footer-logo--padding">
				<img src={logo_l3} />
				<img src={logo_l2} />
				<img src={logo_l1} />
				<img src={logo_v} />
				<img src={logo_r1} />
				<img src={logo_r2} />
				<img src={logo_r3} />
			</div>
		</footer>
      </div>
    );
  }
}
